<template>
  <div class="has-fullheight py-3">
    <div v-if="isLoaded" class="box is-radius-large is-paddingless moderator-updates-container">
      <template v-if="isModeratorExhibitorView">
        <div class="moderator-updates-container-title">
          {{ $t("moderator.changes_title") }}
        </div>

        <template v-if="exhibitorUpdates.length">
          <exhibitor-update-list-item
            v-for="(exhibitorUpdate, index) in exhibitorUpdates"
            :key="`exhibitor-update-${exhibitorUpdate.id}`"
            :exhibitor-update="exhibitorUpdate"
            :button-visible="index === 0"
          ></exhibitor-update-list-item>
        </template>
        <empty-exhibitor-updates-placeholder v-else @start="openExhibitorUpdate"></empty-exhibitor-updates-placeholder>
      </template>
      <router-view v-else class="has-fullheight"></router-view>
    </div>
    <fetching-updates-placeholder class="has-fullheight" v-else-if="isLoading"></fetching-updates-placeholder>
    <fetching-error-placeholder class="has-fullheight" v-else :error="exhibitorUpdatesError"></fetching-error-placeholder>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Constants from "@/web/constants";
import EmptyExhibitorUpdatesPlaceholder from "@/web/components/moderator/placeholders/EmptyExhibitorUpdatesPlaceholder";
import ExhibitorUpdateListItem from "@/web/components/moderator/updates/ExhibitorUpdateListItem";
import FetchingUpdatesPlaceholder from "@/web/components/moderator/placeholders/FetchingUpdatesPlaceholder";
import FetchingErrorPlaceholder from "@/web/components/moderator/placeholders/FetchingErrorPlaceholder";

export default {
  name: "ModeratorExhibitor",
  components: {
    FetchingErrorPlaceholder,
    FetchingUpdatesPlaceholder,
    ExhibitorUpdateListItem,
    EmptyExhibitorUpdatesPlaceholder,
  },
  props: {
    exhibitorId: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.fetchExhibitorUpdates({ exhibitorId: this.exhibitorId });
  },

  computed: {
    ...mapGetters("exhibitorModerator", ["getUpdatesForExhibitorId", "forceNavigateBack", "isLoading", "isLoaded"]),
    ...mapState("exhibitorModerator", ["exhibitorUpdatesError"]),

    exhibitorUpdates() {
      return this.getUpdatesForExhibitorId(this.exhibitorId);
    },

    isModeratorExhibitorView() {
      return this.$route.name === Constants.ROUTE_MODERATOR_EXHIBITOR;
    },
  },

  methods: {
    ...mapActions("exhibitorModerator", ["fetchExhibitorUpdates"]),

    replaceRouteToModeratorMain() {
      this.$router.replace({ name: Constants.ROUTE_MODERATOR_MAIN });
    },

    openExhibitorUpdate() {
      this.$router.push({
        name: Constants.ROUTE_MODERATOR_EXHIBITOR_CREATE_UPDATE,
        params: {
          exhibitorId: this.exhibitorId,
        },
      });
    },
  },

  watch: {
    forceNavigateBack: {
      immediate: false,
      handler: function (newValue) {
        if (newValue) {
          this.replaceRouteToModeratorMain();
        }
      },
    },
  },
};
</script>

<style scoped></style>
