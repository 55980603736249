<template>
  <div class="is-relative">
    <div v-if="uploadVisible">
      <b-field>
        <b-upload v-model="file" drag-drop :accept="accept" class="has-fullwidth">
          <section class="section">
            <div class="content has-text-centered">
              <p>{{ $t("exhibitor_form.exhibitor_picture_file_hint") }}</p>
            </div>
          </section>
        </b-upload>
      </b-field>
      <b-button class="button is-small" type="is-warning" v-if="value" @click="editMode = !editMode">
        {{ $t("common.cancel") }}
      </b-button>
    </div>
    <div class="box is-radius-large image-attachment-file-input is-paddingless" v-else>
      <div class="attachment-image-container is-relative" :style="pictureStyle">
        <div class="buttons-container">
          <b-button class="button px-1 mx-1" @click="onRemoveClick()">
            <div class="button-icon">
              <trash-bin-icon class="svg-icon has-fill-primary"></trash-bin-icon>
            </div>
          </b-button>
          <b-button class="button px-1" @click="editMode = !editMode">
            <div class="button-icon">
              <edit-icon class="svg-icon has-fill-primary"></edit-icon>
            </div>
          </b-button>
        </div>
      </div>
    </div>

    <b-loading v-if="isRequesting" :active="true" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
import RequestMixin from "@/shared/mixins/RequestMixin";
import axios from "@/web/services/axios";
import EditIcon from "@/assets/icons/edit.svg";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";

export default {
  name: "ImageAttachmentFileInput",
  mixins: [RequestMixin],
  components: {
    EditIcon,
    TrashBinIcon,
  },
  props: {
    accept: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      editMode: false,
      file: null,
    };
  },

  requests: {
    async createAttachment(file) {
      const formData = new FormData();
      formData.append("file", file);
      await axios
        .post("attachments", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(result => {
          this.editMode = false;
          this.$emit("input", result.data);
        });
    },
  },

  methods: {
    onRemoveClick() {
      this.$emit("input", null);
    },
  },

  computed: {
    uploadVisible() {
      return !this.value || this.editMode;
    },

    pictureStyle() {
      if (this.value) {
        const imageUrl = this.value.file_thumbnail_750_url;
        return {
          backgroundImage: `url("${imageUrl}")`,
        };
      }
      return null;
    },
  },

  watch: {
    file: {
      immediate: false,
      handler: function (newValue) {
        if (newValue) {
          this.createAttachment(newValue);
        }
      },
    },
  },
};
</script>

<style scoped></style>
