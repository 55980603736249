<template>
  <div class="empty-placeholder-container">
    <div class="empty-placeholder-icon">
      <info-icon class="svg-icon has-fill-primary"></info-icon>
    </div>
    <div class="empty-placeholder-text">
      {{ $t("moderator.empty_exhibitor_accesses_placeholder") }}
    </div>
  </div>
</template>

<script>
import InfoIcon from "@/assets/icons/info.svg";

export default {
  name: "EmptyExhibitorAccessesPlaceholder",
  components: { InfoIcon },
};
</script>
