<template>
  <div class="has-fullwidth">
    <attachments-file-input @input="onNewAttachments"></attachments-file-input>
    <div class="attachments-list-container mt-1">
      <attachment-file-list-view
        v-for="attachment in value"
        :key="`attachment-${attachment.id}`"
        :attachment="attachment"
        @delete="onDeleteAttachment"
        class="mt-1"
      ></attachment-file-list-view>
    </div>
  </div>
</template>

<script>
import AttachmentsFileInput from "@/shared/components/form/AttachmentsFileInput";
import AttachmentFileListView from "@/shared/components/form/AttachmentFileListView";
export default {
  name: "AttachmentsListInput",
  components: { AttachmentFileListView, AttachmentsFileInput },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onNewAttachments(attachments) {
      this.$emit("input", [...this.value, ...attachments]);
    },

    onDeleteAttachment(attachmentId) {
      this.$emit(
        "input",
        this.value.filter(it => it.id !== attachmentId)
      );
    },
  },
};
</script>

<style scoped></style>
